import categoryGroup from '@/modules/strubs/categoryGroup'

const category = {
  id: 0,
  name: '',
  active: false,
  subjects_count_total: 0,
  subjects_count_active: 0,
  subjects_count_inactive: 0,
  created_at: '',
  updated_at: '',
  group: categoryGroup
}

export default category
